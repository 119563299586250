<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="titleHalaman"/>
        </v-col>
      </v-row>
      <div class="px-6 mt-4">Rekap Jumlah Total</div>
      <v-row class="px-5 py-2" no-gutters>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Rata-rata Klien
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.average_klien?this.dataSet.average_klien:"0" }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Jumlah Komunitas Biro
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.jml_community?this.dataSet.jml_community:"0" }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Jumlah Karyawan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.jml_karyawan?this.dataSet.jml_karyawan:"0" }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Rata-rata Event
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.total_event_semua_klien?this.dataSet.total_event_semua_klien:"0" }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="px-6 mt-4">Rekap Event</div>
      <v-row class="px-5 py-2" no-gutters>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Jumlah Event Berjalan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.event_berjalan }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Jumlah Event Selesai
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.event_selesai }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Jumlah Event Komunitas Biro
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                {{ this.dataSet.jml_event_community }}
              </h3>
              <v-skeleton-loader
                v-if="loading"
                width="50%"
                height="30px"
                type="image"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="px-6 mt-4">Rekap Pendapatan</div>
      <v-row v-if="!loading && dataSet != null" class="px-5 py-2" no-gutters>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Rata-rata Pendapatan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 class="txt_color_default">
                09
              </h3>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Range Pendapatan per Layanan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 class="txt_color_default">
                09
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="loading" no-gutters>
          <v-col
            v-for="(item, n) in 3"
            :key="n"
            cols="4"
            md="4"
            sm="4"
            class="pa-1"
          >
          <v-skeleton-loader
            width="100%"
            height="70px"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row> -->
      <!-- <div class="px-6 mt-4">Rekap Karyawan</div>
      <v-row v-if="!loading && dataSet != null" class="px-5 py-2" no-gutters>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Rata-rata Gaji Karyawan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 class="txt_color_default">
                09
              </h3>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Range Gaji Karyawan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 class="txt_color_default">
                09
              </h3>
            </div>
          </div>
        </v-col>
        <v-col cols="4" md="3" sm="4" class="pa-1">
          <div class="shadow">
            Waktu Aktif Karyawan
            <div
              class="mt-2 d-flex align-center justify-space-between"
              style="width: 60%"
            >
              <div style="border: 2px solid #633b18; height: 20px"></div>
              <h3 class="txt_color_default">
                09
              </h3>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="loading" no-gutters>
          <v-col
            v-for="(item, n) in 3"
            :key="n"
            cols="4"
            md="4"
            sm="4"
            class="pa-1"
          >
          <v-skeleton-loader
            width="100%"
            height="70px"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "detailRekapClient",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      loading: false,
      titleHalaman:'Detail Rekap Klien',
      dataSet: null,
      dataKlien:null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Rekap klien",
          disabled: false,
          href: "/admin/mo/rekap-client",
        },
        {
          text: "Detail Rekap klien",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/biro-analisis/biro-analisis?klien_id=`+ this.$route.params.idKlien,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(data);
          this.dataKlien = data.klien;
          this.titleHalaman = 'Detail Rekap Klien ' + data.klien.nama;
          this.dataSet = data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.shadow {
  box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
}
</style>
